
import { Component } from 'vue-property-decorator';
import { RouteConfig, Route, RawLocation } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';
import { getFullName } from '@/modules/user';
import { getEnumByName } from '@/util';
import { EmailTemplateType } from '@/modules/emailtemplate';

@Component<Invoice>({
  beforeRouteEnter(to, from, next): void {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
})
export default class Invoice extends SubModule {
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM] {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  /**
   * Default handler for the beforeRouteEnter navigation guard.
   */
  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void) {
    // We need this because of the toolbar
  }

  protected _getRoutes(): RouteConfig[] {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[] {
    // E-mail route as single tab
    if (this.$route.meta && this.$route.meta.isEmailRoute === true) {
      const emailTemplateType = getEnumByName(EmailTemplateType, this.$route.params.emailTemplateType);
      return [
        {
          title: emailTemplateType ? this.$root.$t(`module.emailtemplate.enums.EmailTemplateType.${emailTemplateType}`).toString() : this.$t('email.title').toString(),
          icon: '$vuetify.icons.sendEmail',
          route: this.$route.fullPath,
        },
      ];
    }

    const activeItem = this.activeItem;
    if (activeItem && activeItem.$isCreated()) {
      return [
        {
          title: `${activeItem.$original.invoiceReference}`,
          icon: `$vuetify.icons.edit`,
          route: `/invoice/${activeItem.id}`,
        },
      ];
    }
    return [];
  }
}
